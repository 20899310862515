import React from 'react';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Hero from 'components/Hero';
import Section from 'components/Section';
import { graphql } from 'gatsby'

export default function PageEvents({data}) {
  const theme = 'dark'
  return (
    <Layout theme={theme}>
      <Seo title="Events" keywords={['']} />
      <Hero
        theme={theme}
        image={data.events.childImageSharp.fluid}
        titleFirst="Beach"
        titleSecond="Events"
      />
      <Section />
    </Layout>
  );
}

export const query = graphql`
  query {
    events: file(relativePath: { eq: "events.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
